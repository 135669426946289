<template>
  <v-dialog
    v-model="dialog"
    scrollable
    persistent
    max-width="500px"
    transition="dialog-transition"
  >
    <v-card flat>
      <v-card-title>
        {{ $t("extracuricular.input_member") }}
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-5">
        <v-combobox
          v-model="modelClass"
          :items="dataClassList"
          :loading="loadingClass"
          :disabled="loadingClass"
          :label="$t('master_data.student.tab1_data.choose_class')"
          :item-text="concatString"
          dense
          item-value="id"
          outlined
          hide-details
          @change="changeClass"
        />
        <v-data-table
          v-model="modelTable"
          :headers="tableHeaders"
          :items="dataTable.data"
          :page.sync="queryStudent.page"
          :loading="loadingTable"
          item-key="id"
          show-select
          hide-default-footer
          disable-pagination
          class="elevation-0 mt-5"
        >
          <template #item.data-table-select="{ item, isSelected, select }">
            <v-simple-checkbox
              v-if="item.is_join === 0"
              :value="isSelected"
              :disabled="loadingTable"
              @input="select($event)"
            />
            <v-icon v-else color="grey lighten-1">mdi-square-outline</v-icon>
          </template>
        </v-data-table>
        <!-- <Pagination
          :length="dataTable.length"
          :total="dataTable.total"
          :current-page="queryStudent.page"
          :limit="queryStudent.limit"
          :handler="paginateChange"
        /> -->
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          :disabled="loadingSave"
          :dark="!loadingSave"
          color="primary"
          outlined
          depressed
          @click="close"
        >
          {{ $t("app.cancel") }}
        </v-btn>
        <v-btn
          :loading="loadingSave"
          :disabled="modelTable.length === 0"
          class="gradient-primary"
          depressed
          dark
          @click="save"
        >
          {{ $t("app.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { get_class_list } from "@/api/admin/schoolClass";
import { get_school_year_list } from "@/api/admin/schoolClass";
import { inputMember, studentList } from "@/api/admin/academic/extracurricular";

export default {
  props: {
    dialog: Boolean
  },
  // components: {
  //   Pagination: () => import("@/components/Pagination")
  // },
  watch: {
    dialog(value) {
      if (value) {
        this.reset();
        this.getClassList();
      }
    }
  },
  data() {
    return {
      modelTable: [],
      modelClass: null,
      loadingTable: false,
      loadingSave: false,
      tableHeaders: [
        {
          text: this.$i18n.t("app.name"),
          value: "name"
        },
        {
          text: this.$i18n.t("app.nis"),
          value: "nis"
        }
      ],
      dataTable: { data: [], total: 0, length: 0 },
      queryStudent: {
        page: 1,
        limit: 10,
        search: "",
        classroom: 0,
        extra: this.$route.params.id
      },
      dataAllList: {
        sort: "class_master",
        order: "DESC",
        school_year: []
      },
      dataClassList: [],
      loadingClass: false
    };
  },
  methods: {
    save() {
      this.loadingSave = true;
      const idStudent = this.modelTable.map(item => item.id);
      const body = { extra: this.queryStudent.extra, student: idStudent };
      inputMember(body)
        .then(response => {
          if (response.data.code) {
            this.snackBar(
              true,
              this.$i18n.t("extracuricular.msg.input_member")
            );
          } else {
            this.snackBar(false, response.data.message);
          }
          this.close();
          this.refresh();
          this.loadingSave = false;
        })
        .catch(error => {
          console.error("save()\n", error);
          this.close();
          this.refresh();
          this.loadingSave = false;
        });
    },
    reset() {
      this.modelClass = null;
      this.modelTable = [];
      this.dataTable = { data: [], total: 0, length: 0 };
    },
    paginateChange(page, limit) {
      this.queryStudent.limit = limit;
      this.queryStudent.page = page;
      this.getStudent();
    },
    changeClass(item) {
      this.queryStudent.classroom = item.id;
      this.getStudent();
    },
    snackBar(isSuccess, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isSuccess ? "success" : "error"
      });
    },
    async getStudent() {
      this.loadingTable = true;
      this.modelTable = [];
      try {
        const response = await studentList(this.queryStudent);
        if (response.data.code) {
          const student = response.data.data;
          this.dataTable.data = student;
          // this.dataTable.total = student.total;
          // this.dataTable.length = student.last_page;
        } else {
          this.snackBar(false, response.data.message);
        }
      } catch (error) {
        console.error("getStudent()\n", error);
        this.loadingTable = false;
      }
      this.loadingTable = false;
    },
    async getClassList() {
      this.loadingClass = true;
      try {
        const response = await get_school_year_list(false);
        if (response.data.code) {
          let r = response.data.data;
          this.dataAllList.school_year = [r.find(d => d.status === 1)?.id];
          this.dataClassList = await get_class_list(this.dataAllList).then(
            r => r.data.data
          );
        }
      } catch (error) {
        console.error("getClassList()\n", error);
        this.loadingClass = false;
      }
      this.loadingClass = false;
    },
    concatString: item => item.grade + " / " + item.name,
    close() {
      this.$emit("close");
    },
    refresh() {
      this.$emit("refresh");
    }
  }
};
</script>
